
    
.switcher
    display: flex
    max-width: 835px

    &>div
        flex: 1


    .switch

        ul
            max-width: 350px
            box-shadow: inset 0px 0 5px -2px $black
            margin: 0 6px 0 0
            border-radius: 3px
            height: 250px
            background: var(--color-white-blue)
            padding: 15px 0
            list-style: none
            overflow-x: hidden
            overflow-y: auto

            li
                background: $color-primary
                margin: 10px 14px
                border-radius: 2px
                box-shadow: 0 0 5px -3px $black
                color: $white
                padding: 5px 8px
                cursor: pointer
                cursor: grab

                p
                    margin: 0 
                    position: relative

                    span
                        text-align: right
                        background: $color-primary-dark
                        border-radius: 4px
                        font-size: 11px
                        float: right
                        color: $white
                        position: absolute
                        right: -8px
                        height: 29px
                        top: -5px
                        padding: 8px