
.signin {

    .signwith {
        border-bottom: 1px solid var(--color-white-grey);
        width: calc(100% + 30px);
        margin-left: -15px;
        margin-bottom: 31px;
        padding: 0 10px 8px;
        margin-top: -5px;
        font-size: 13px;
        text-align: center;
    }

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 97%;
    max-width: 360px;
    
    .form {

        border: 1px solid var(--color-white-grey);
        padding: 15px;
        border-radius: 6px;
        overflow: hidden;

        &.service {
            h1 {
                font-size: 25px;
                margin: 10px 0; 
            }
            p a {
                color: var(--color-primary-dark);
            }
        }

        header {

            text-align: center;
            margin: 17px 0 34px;

            h1 {
                margin: 0;
            }
            p {
                margin: 0;
            }

        }

        input {
            display: block;
            width: 80%;
            margin: 11px auto;
        }

        .footer {
            margin: 33px auto 34px auto;
            width: 80%;         
            .btn.right {
                float: right;
            }
        }
    }

    > ul {
        list-style: none;
        padding: 0;
        display: block;
        text-align: right;
        font-size: 12px;

        a {
            color: var(--color-white-grey);
        }

        li {
            display: inline-block;
            margin: 0 7px;
        }
    }
}

.signin .form .hidden {
    display: none !important;
}
.signin .form .overlay {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    background: var(--white);

    animation: overlay-fade 500ms ;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);

    > div {
        width: 100%;
    }

    svg {
        fill: rgba(0, 255, 0, 0.549);
        width: 100px;
        height: 68px;
        margin: 0 auto;
        display: block;
    }


}

.signin .form .overlay.privacy {
    padding: 20px;
    display: unset;

    ul {
        text-align: left;
        padding: 0 0 0 22px;
        list-style: none;
        li {
            margin: 0 0 13px;
        }
    }

    > a {
        text-align: left;
        width: 100%;
        display: block;
        margin-left: 22px;
    }

    p {
        text-align: left;
        line-height: 28px;
        width: calc(100% - 20px);
        margin: 0 auto;
    }

    .btn.light {
        margin-right: 40px;
    }

}


@keyframes overlay-fade{
    0% {
        transform: translateY(20%);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        
        transform: translateY(0);
    }
}

@media (max-width: 500px) {

    .signin .form.default {
        border: unset;
        padding: 0;
        border-radius: 0;
    }

    .signin form header h1 {
        margin: 0;
        font-size: 55px;
    }

    .signin {
        
        top: 0;
        left: 0;
        transform: unset;
        width: 100%;
        height: 100%;
        max-width: unset;

        .signwith {
            border: 0;
        }

        ul {
            text-align: center;
            width: 100%;
            bottom: 10px;
            position: absolute;         
        }
        .form.default {
            width: 95%;
            margin: 0 auto;
            left: 50%;
            top: 117px;
            position: fixed;
            transform: translateX(-50%);
            overflow: unset;
        }
        .form.default input {
            width: 100%;
            font-size: 20px;
        }
        .form.default .footer {
            margin: 50px 0;
            width: 100%;
        }

    }

}