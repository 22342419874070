
.errorpage
    text-align: center
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

    a
        text-decoration: none
        color: $color-primary

        &:hover
            color: $color-primary-dark

    img
        width: 160px
