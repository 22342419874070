.openmodal {
    filter: blur(4px);
    transition: 150ms;

    &::after {
        content: "";
        height: 100%;
        background: transparent;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.modal {
    position: absolute;
    top: calc(50% - 25px);
    left: 50%;
    z-index: 10000000;
    transform: translate(-50%, -50%) scale(1);
    background: var(--color-white-blue);
    border-radius: 5px;
    padding: 13px 0 0 0;
    border: 1px solid var(--color-light-shadow);
    width: min-content;
    min-width: 340px;

    animation: openmodal-animation 150ms ease;

    input {
        width: 100%;
    }

    header {
        border-bottom: 1px solid var(--color-light-shadow);
        margin-bottom: 16px;
        padding: 0 30px 0 15px;

        h1 {
            margin: 1px 0 8px;
            font-size: 19px;
        }
    }
    .body {
        padding: 0 15px;
    }
    .cont {
        padding: 12px 15px;
    }
    footer {
        text-align: right;
        margin: 20px 15px 15px;

        .btn {
            margin-left: 9px;
        }
    }
}

@keyframes openmodal-animation {
    0% { transform: translate(-50%, -50%) scale(.8); }
    100% { transform: translate(-50%, -50%) scale(1); }
}

@media (max-width: 500px) {
    .modal {
        width: 90%;
    }
}
