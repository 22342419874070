.progressbar
    width: 100%
    height: 10px
    background: var(--color-white-blue)
    max-width: 350px

    > div
        width: 0
        height: 100%
        background: $color-primary
        text-align: center
        font-size: 9px