
.notify
    position: fixed
    top: 20px
    right: 20px
    background: $color-primary-light
    z-index: 1000
    border-radius: 4px
    color: $white
    padding: 10px 15px
    max-width: 385px
    padding-right: 36px
    cursor: pointer

    h1
        font-size: 17px
        margin: 0

    p
        font-size: 14px
        margin: 0

    .close
        font-weight: 900
        font-family: "Font Awesome"
        position: absolute
        right: 13px
        top: 12px
        font-size: 13px

        &:after
            content: "\f00d"