%border-style{
    content: "";
    width: 65px;
    height: 65px;
    position: absolute;
    border-color: var(--color-primary);
    z-index: -1;
}

$border: 4px solid var(--color-primary);

$formLoadingSize: 1px;
$formLoadingMaxSize: 50px;


.form.default {
    
    position: relative;
    max-width: 700px;

    h1 {
        margin: 10px 0 30px;
    }

    .desc {
        font-size: 13px;
        color:  var(--color-white-grey);
    }  

    .invalid {
        border-color: var(--red);
    }
        
    .formGroup{
        margin: 15px 0;
        display: flex;
        align-items: center;

        .label {
            width: 140px;
            display: inline-block;
            min-width: 140px;
            max-width: 140px;
        }   
        .input {
            width: 200px;
            &.large {
                width: auto;
            }
        }
        textarea.input {
            width: 100%;
            resize: no-resize;
            resize: vertical;
            height: 110px;
            max-height: 250px;
        }
    }

    &.centered {
        padding: 10px 20px;
        top: 60px;
        margin: 0 auto;

        &::before {
            @extend %border-style;
            top: 0;
            left: 0;
            border-top: $border;
            border-left: $border;
        }
        &::after {
            @extend %border-style;
            bottom: 0;
            right: 0;
            border-bottom: $border;
            border-right: $border;
        }
    }

    .buttons {
        padding: 10px 6px;
        text-align: right;

        a {
            cursor: pointer;
            margin: 0 20px;
        }

        &.left {
            text-align: left;
        }
    }

}

.input-flex {
    display: flex;
    align-items: center;
    > * {
        margin-right: 10px;
    }
}

.form .time-based-locked {
    p {
        font-size: 26px;
        color: var(--red);
        margin-bottom: -28px;
        text-align: center;
    }
    h1 {
        text-align: center;
        font-size: 55px;
        font-weight: bold;
        margin: 55px;
    }
}

.form.loading {

    > * {
        transition: 400ms;
    }

    * {
        pointer-events: none;
    }
      
    border-color: #fff;
    animation: form-pulse 2s ease infinite;

    &:after {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: progress;
        background: var(--white);
        transition: 400ms;
    }
    
}

@keyframes form-pulse {
    0% {
        border-color: #fff;
    }
    70% {
        border-color: var(--color-white-grey);
    }
    100% {
        border-color: #fff;
    }
}

.form.failed {

    border-color: var(--red) !important;

    .input {
        border-color: var(--red)  !important;
    }

}

.form.shake {
    animation-iteration-count: infinite;
    animation: shake 0.5s;
}

@keyframes shake {
    0% {transform: translate(1px, 1px) rotate(0deg);}
    10% { transform: translate(-1px, -2px) rotate(-1deg);}
    20% {transform: translate(-3px, 0px) rotate(1deg);}
    30% {transform: translate(3px, 2px) rotate(0deg);}
    40% { transform: translate(1px, -1px) rotate(1deg);}
    50% {transform: translate(-1px, 2px) rotate(-1deg);}
    60% {transform: translate(-3px, 1px) rotate(0deg);}
    70% { transform: translate(3px, 1px) rotate(-1deg);}
    80% {transform: translate(-1px, -1px) rotate(1deg);}
    90% {transform: translate(1px, 2px) rotate(0deg);}
    100% {transform: translate(1px, -2px) rotate(-1deg);}
}

@media (max-width: 500px) {

    .form.default .input {
        width: 95%;
    }
    .form.default {
        display: block;
    }
    .form.default .label {
        width: 100% !important;
        display: block;
        margin: 11px 0;
        max-width: 100% !important;
    }

    .form.default .buttons {
        text-align: center;
    }

}