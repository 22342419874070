.grid
    display: grid
    grid-gap: 30px
    grid-template-columns: repeat(auto-fit, minmax(400px, 500px))
    grid-auto-flow: row dense
    width: 100%

    .item
        padding: 8px 8px
        background: var(--color-white-blue)
        border-radius: 2px
        height: 190px