body .aside {

    background: var(--color-primary);
    height: calc(100vh - 97px);
    width: 60px;
    border-top-right-radius: 9px;
    position: relative;

    .logo {
        position: absolute;
        top: -75px;
        width: 60px;
        left: 0;
        height: 105px;
        z-index: -1;
        padding: 20px 8px;
        background: var(--color-white-blue);
    }

    ul {
        list-style: none;
        padding: 0;
        color: var(--black);

        li.dropmenu {
            color: var(--color-white-blue);
            width: 60px;
            text-align: center;
            font-size: 21px;
            padding: 6px 0;
            cursor: pointer;
            transition: 100ms;
            z-index: 11;
            position: relative;

            img {
                width: 19px;
            }

            &:hover i,
            &:hover img {
                    transform: scale(1.1);
            }

            &:active i,
            &:active img {
                    transform: scale(1);
            }
            ul.menu {
                transform: scale(0) translateY(80%) translateX(-100%);
                position: absolute;
                bottom: 8px;
                background: var(--color-white-blue);
                z-index: 10000;
                min-width: 130px;
                left: 46px ;
                border-radius: 4px;
                box-shadow: 0 0 14px -7px var(--color-white-grey);
                border: 1px solid var(--color-light-shadow);
                text-align: left;
                padding: 8px 0;
                width: auto;

                span.title {
                    color: var(--color-white-grey);
                    font-size: 11px;
                    padding: 0 12px;
                    margin: 5px 0 6px;
                    display: block;
                }
                li {
                    width: 100%;
                    color: var(--black);
                    font-size: 14px;
                    padding: 6px 12px;
                    text-align: left;
                    white-space: nowrap;

                    &:hover {
                        background: var(--color-white-blue-hover);
                    }
                }
            }
        }

        li.dropmenu.open {
            i, img {
                transform: scale(1.1);
            }
            ul.menu {
                transition: 100ms;
                transform: scale(1) translateY(0) translateX(0);
            }
        }
    }


    ul.bottom {
        position: absolute;
        bottom: 0;
    }

    .sidebar {
        position: fixed;
        top: 0;
        left: 0;
        background: var(--color-primary);
        width: 340px;
        z-index: 10;
        height: calc(100% - 22px);
        transform: translateX(-110%);
    }
}

body > .aside.open {

    div.logo {
        z-index: 100;
        height: 60px;
        border-bottom-right-radius: 22%;
        padding: 16px 9px;
        transition: 100ms;
        padding-top: 14px;
    }

    .sidebar {
        transform: translateX(0);
        transition: 100ms;
    }

}

@media (max-width: 500px) {

    body .main  .aside {
        height: 60px;
        position: fixed;
        left: 0;
        top: -75px;
        width: 100%;
        border-top-right-radius: 0;
        z-index: 10000;
        display: flex;

        ul li.dropmenu {
            padding: 3px 0;
        }
        ul.top {
            position: absolute;
            right: 0;
            li {
                font-size: 34px;
                padding: 0;
                margin: -4px 5px;
            }
        }
        ul.bottom {
            position: unset;
            display: flex;
            margin-left: 17px;
        }
        div.logo {
            position: unset;
            height: 60px;
            padding: 14px 4px;
        }
    }

    body .aside ul li.dropmenu.open ul.menu {
        display: block;
    }
    body .aside ul li.dropmenu ul.menu {
        transform: unset;
        bottom: unset;
        min-width: unset;
        left: unset;
        box-shadow: unset;
        width: auto;
        display: none;
    }

}