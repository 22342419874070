.darklightchange
    display: flex
    margin: 0 4px

    & > i
        font-size: 14px
        margin: 0
        padding: 4px 0 0 0

    .toggle
        padding: 4px 5px 0