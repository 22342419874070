@import 'variablen/color';
@import 'base/base';
@import 'base/layout';
@import 'base/error';

@import 'component/elements';
@import 'component/notify';
@import 'component/switcher';

@import 'items/darkmodetoggle';
@import 'items/cart';
@import 'items/progressbar';

$font-regular: Roboto-Regular;
$font-medium: Roboto-Medium;

a {
    text-decoration: none;
    color: var(--black);
}

html, body,
body > #root {
    height: 100%;
}

body .main {
    height: calc(100% - 22px);
    display: flex;
    .aside  {
        background: var(--color-primary);
        height: calc(100% - 70px);
        width: 60px;
        border-top-right-radius: 9px;
        margin-top: 75px;
    }
}

body {
    font-family: $font-regular;
    background: $white;
    margin: 0;
    color: $black;
    
    > div {
        overflow: hidden;
    }

    #portal.open {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    // main;
    //     width: calc(100vw - 60px);
    //     height: calc(100vh - 22px);
    //     display: flex;
    //     margin-left: 60px;
    //     overflow: auto;
    //     position: relative;


    .content {
        overflow: auto;
        width: 100%;
        padding: 25px 20px;
        position: relative;
        animation: page-fade 500ms ;
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
        
        & > *:last-child {
            padding-bottom: 20px;
        }
        .subtitle {
            font-size: 13px;
            font-weight: normal;
            margin-bottom: 20px;
        }
        
        .filter {
            margin: 25px 0;

            .right {
                float: right;
            }

            .flex {
                display: flex;
                > div {
                    flex: 1;
                }

                .text {
                    max-width: 100px;
                    font-size: 13px;
                    margin: 6px 0;
                }

                .select {
                    cursor: pointer;
                    font-size: 13px;
                }
            }
        }
    }

    > div > .footer {
        background: $color-primary;
        height: 22px;
        position: absolute;
        bottom: 0;
        width: 100%;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                transition: 100ms;
                i {
                    color: $white;
                    padding: 4px 8px;
                    font-size: 14px;
                }

                &.blink {
                    animation: blink 2s linear infinite;
                }
            }
            &.right {
                position: absolute;
                right: 0;
                top: 0;
                display: flex;
            }

        }

    }

}

@keyframes page-fade {
    0% {
        transform: translateX(-10px);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        
        transform: translateY(0);
    }
}


@keyframes blink {
    0% {opacity: 0;}
    50% { opacity: 1;}
    100% { opacity: 0;}
}

@media (max-width: 500px){

    html, body,
    body > #root {
        height: unset;
    }

    body .footer {
        display: none;
    }
    
    body {
        width: 100%;
        height: 100%;
        display: flex;
        margin-left: 0;
        overflow: auto;
        margin-top: 60px;
        min-height: calc(100vh - 60px);
        position: relative;
    }

}